import React from "react"
import classes from "./Firewall.module.css"
import { ChangeEvent, Component, FormEvent } from "react"
// import { toast } from "react-toastify"
import { FirewallInformation } from "../../entities/FirewallInformation"
import { setAllowedPortsTcp, setAllowedPortsUdp, setAutoCaptivePortalEnabled, setInternetBlockingEnabled, setInternetWhitelist } from "../../helpers/MediaspotHelper"
import { unzip, zip } from "../../helpers/ZipHelper"
import defaultClasses from "../DefaultStyle.module.css"
import { Trans } from 'react-i18next'
import {I18nextProvider} from "react-i18next";
import i18n from '../../i18n'
import SetButton from "../SetButton/SetButton"
import LoadingCheckbox from "../../LoadingCheckbox/LoadingCheckbox"


type IState = { 
    firewallInformation: FirewallInformation,
    whiteListEditingText: string,

    isTCPPortsRequestLoading: boolean,
    isUDPPortsRequestLoading: boolean,
    isInternetBlockingRequestLoading: boolean,
    isAutomaticPortalRequestLoading: boolean,
    isWhitelistRequestLoading: boolean
}

type IFirewallInformationProps = {
    deviceId: string
    firewallInformation: FirewallInformation,
    onFirewallUpdate: (firewallInfo: FirewallInformation) => void,
    addToast: Function
}

export class FirewallClass extends Component<IFirewallInformationProps, IState>{
    

    constructor(props: IFirewallInformationProps){
        super(props)
        this.state = {
            firewallInformation: props.firewallInformation,
            whiteListEditingText: "",
            isTCPPortsRequestLoading: false,
            isUDPPortsRequestLoading: false,
            isInternetBlockingRequestLoading: false,
            isAutomaticPortalRequestLoading: false,
            isWhitelistRequestLoading: false

        }
    }

    async componentDidMount(){
        const firewallInformationCopy = {...this.state.firewallInformation}
        const decodedWhitelist = await unzip(firewallInformationCopy.internetWhitelist)

        this.setState({
            whiteListEditingText: decodedWhitelist || ""
        })
    }

    componentDidUpdate(prevProps: IFirewallInformationProps){
        if(prevProps.firewallInformation !== this.props.firewallInformation){
            this.setState({firewallInformation: this.props.firewallInformation})
        }
    }

    // TCP

    onOutgoingTCPPortsChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const firewallInformationCopy = {...this.state.firewallInformation}
        firewallInformationCopy.allowedOutgoingTCPPorts = event.target.value
        this.setState({ firewallInformation: firewallInformationCopy })
    }

    onOutgoingTCPPortsSubmit = async(event: FormEvent) => {
        event.preventDefault()
        await this.setState({isTCPPortsRequestLoading: true})
        const response = await setAllowedPortsTcp(this.props.deviceId, this.state.firewallInformation.allowedOutgoingTCPPorts)
        await this.setState({isTCPPortsRequestLoading: false})
    
        if(response.status === true){
            this.props.addToast(i18n.t("AllowedTCPPortsRequest"), {
                appearance: 'info',
                autoDismiss: true,
            });
            this.props.onFirewallUpdate(this.state.firewallInformation)
        }else {
            this.props.addToast(i18n.t("ErrorAllowedTCPPortsRequest"), {
                appearance: 'error',
                autoDismiss: true,
            });

        }
    }

    // UDP

    onOutgoingUDPPortsChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const firewallInformationCopy = {...this.state.firewallInformation}
        firewallInformationCopy.allowedOutgoingUDPPorts = event.target.value
        this.setState({ firewallInformation: firewallInformationCopy })
    }

    onOutgoingUDPPortsSubmit = async(event: FormEvent) => {
        event.preventDefault()
        
        await this.setState({isUDPPortsRequestLoading: true})
        const response = await setAllowedPortsUdp(this.props.deviceId, this.state.firewallInformation.allowedOutgoingUDPPorts)
        await this.setState({isUDPPortsRequestLoading: false})

        if(response.status === true){
            this.props.addToast(i18n.t("AllowedUDPPortsRequest"), {
                appearance: 'info',
                autoDismiss: true,
            });
            this.props.onFirewallUpdate(this.state.firewallInformation)
        }else {
            this.props.addToast(i18n.t("ErrorAllowedUDPPortsRequest"), {
                appearance: 'error',
                autoDismiss: true,
            });
        }
    }


    // Internet blocking


    onInternetBlockingChanged = async(event: React.MouseEvent<HTMLInputElement>) => {
        const firewallInformationCopy = {...this.state.firewallInformation}
        firewallInformationCopy.isInternetBloking = (event.target as HTMLInputElement).checked
        
        
        await this.setState({isInternetBlockingRequestLoading: true})
        const response = await setInternetBlockingEnabled(this.props.deviceId, firewallInformationCopy.isInternetBloking)
        await this.setState({isInternetBlockingRequestLoading: false})

        if(response.status === true){
            this.setState({ firewallInformation: firewallInformationCopy })
            this.props.addToast(i18n.t("InternetBlockingRequest"), {
                appearance: 'info',
                autoDismiss: true,
            });
            this.props.onFirewallUpdate(this.state.firewallInformation)
        }else {
            this.props.addToast(i18n.t("ErrorInternetBlockingRequest"), {
                appearance: 'error',
                autoDismiss: true,
            });
        }
    }


    // Auto captive portal
    
    onAutomaticCaptivePortalChanged = async(event: React.MouseEvent<HTMLInputElement>) => {
        const firewallInformationCopy = {...this.state.firewallInformation}
        firewallInformationCopy.isAutomaticCaptivePortalEnabled = (event.target as HTMLInputElement).checked
        
        await this.setState({isAutomaticPortalRequestLoading: true})
        const response = await setAutoCaptivePortalEnabled(this.props.deviceId, firewallInformationCopy.isAutomaticCaptivePortalEnabled)
        await this.setState({isAutomaticPortalRequestLoading: false})

        if(response.status === true){
            this.setState({ firewallInformation: firewallInformationCopy })
            this.props.addToast(i18n.t("AutomaticCaptivePortalRequest"), {
                appearance: 'info',
                autoDismiss: true,
            });
            this.props.onFirewallUpdate(this.state.firewallInformation)
        }else {
            this.props.addToast(i18n.t("ErrorAutomaticCaptivePortalRequest"), {
                appearance: 'error',
                autoDismiss: true,
            });
        }
    }


    // Internet White list
    onInternetWhitelistValueChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            whiteListEditingText: event.target.value
        })
    }

    onInternetWhitelistSubmit = async(event: FormEvent) => {
        event.preventDefault()
        
        const encodedWhitelist = await zip(this.state.whiteListEditingText)
        if(!encodedWhitelist){
            this.props.addToast(i18n.t("ErrorWhitelistSaving"), {
                appearance: 'error',
                autoDismiss: true,
            })
        }else {
            await this.setState({isWhitelistRequestLoading: true})
            const response = await setInternetWhitelist(this.props.deviceId, encodedWhitelist)
            await this.setState({isWhitelistRequestLoading: false})

            if(response.status === true){
                const firewallInfoCopy = {...this.state.firewallInformation}
                firewallInfoCopy.internetWhitelist = encodedWhitelist
                await this.setState({
                    firewallInformation: firewallInfoCopy,
                    // isWhitelistEditing: false
                })

                this.props.addToast(i18n.t("InternetWhitelistRequest"), {
                    appearance: 'info',
                    autoDismiss: true,
                })
                
                this.props.onFirewallUpdate(firewallInfoCopy)
            }else {
                this.props.addToast(i18n.t("ErrorInternetWhitelistRequest"), {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        }
    }


    render(){

        if(JSON.stringify(this.state.firewallInformation) === "{}"){
            return <I18nextProvider i18n={ i18n }>
                <label><Trans>UnavailableFirewallInfo</Trans></label>
            </I18nextProvider>
        }
        return (

            <I18nextProvider i18n={ i18n }>
                <div className={classes.FirewallContainer}>
                    <p>
                    <Trans>FirewallUDPTCPDescription</Trans>
                    </p>

                    <form onSubmit={this.onOutgoingTCPPortsSubmit}>
                        <label><Trans>SetAllowedOutgoingTCPPorts</Trans> :</label>
                        <input className={defaultClasses.DefaultInput}  value={this.state.firewallInformation.allowedOutgoingTCPPorts} onChange={this.onOutgoingTCPPortsChanged}/>
                        <SetButton isLoading={this.state.isTCPPortsRequestLoading} text={"Set"} height={24} />
                    </form>

                    <form onSubmit={this.onOutgoingUDPPortsSubmit}>
                        <label><Trans>SetAllowedOutgoingUDPPorts</Trans> :</label>
                        <input className={defaultClasses.DefaultInput} value={this.state.firewallInformation.allowedOutgoingUDPPorts} onChange={this.onOutgoingUDPPortsChanged}/>
                        <SetButton isLoading={this.state.isUDPPortsRequestLoading} text={"Set"} height={24} />
                    </form>

                    <br />

                    <LoadingCheckbox
                        isLoading={this.state.isInternetBlockingRequestLoading}
                        text={"InternetBlocking"}
                        defaultChecked={this.state.firewallInformation.isInternetBloking}
                        onClick={this.onInternetBlockingChanged}
                        id={"internetBlockingCheckbox"}/>

                    <br />
                    <label><Trans>InternetBlockingDescription</Trans></label>

                    <br /><br />

                    <LoadingCheckbox
                        isLoading={this.state.isAutomaticPortalRequestLoading}
                        text={"AutomaticCaptivePortal"}
                        defaultChecked={this.state.firewallInformation.isAutomaticCaptivePortalEnabled}
                        onClick={this.onAutomaticCaptivePortalChanged}
                        id={"automaticCaptivePortalCheckbox"}/>
                    <br />
                    <label><Trans>AutomaticCaptivePortalDescription</Trans></label>

                    <br />

                    <h5><Trans>InternetWhitelist</Trans></h5>
                    
                    <textarea
                        className={classes.FirewallWhitelistTextarea}
                        value={this.state.whiteListEditingText}
                        onChange={this.onInternetWhitelistValueChanged}
                        rows={30}
                        />
                    <SetButton isLoading={this.state.isWhitelistRequestLoading} onClick={this.onInternetWhitelistSubmit} text={"Set"} height={24} />
                </div>
            </I18nextProvider>
        )
        
    }
}

export const Firewall = (props: any) => {
    //const useToasts = ReactToast.useToasts()
    //return <FirewallClass {...props} {...useToasts}/>
    return <FirewallClass {...props} />
}