import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                // Firewall
                UnavailableFirewallInfo: "Unavailable firewall info",
                FirewallUDPTCPDescription: "Mediaspot performs NAT for outgoing traffic from LAN devices. Allowed outgoing ports are defined below for TCP and UDP (comma-delimited list). All incoming ports are blocked.",
                SetAllowedOutgoingTCPPorts: "Set allowed outgoing TCP ports",
                SetAllowedOutgoingUDPPorts: "Set allowed outgoing UDP ports",
                InternetBlocking: "Internet blocking",
                InternetBlockingDescription: "If set to OFF, LAN devices can make outgoing connections to any domains on the enabled ports. If set to ON, LAN devices can make outgoing connections only to the domains white-listed below.",
                Set: "Set",
                AutomaticCaptivePortal: "Automatic captive portal",
                AutomaticCaptivePortalDescription: "If set to ON, LAN devices will be directed to the Welcome Page automatically (compliant with iOS, Android, Windows captive portal) and have no WAN connectivity. They escape the captive portal by making an HTTP request to welcomepage.fastpoint.media/portalaccept/.",
                InternetWhitelist: "Internet whitelist",
                SaveWhitelist: "Save whitelist",

                // Advanced parameters
                MediaspotName: "Mediaspot name",
                MediacenterHost: "Mediacenter host",
                Tr069Host: "Tr069 host",
                SyncserverWebsocketHost: "Syncserver websocket host",
                FirmwareUpgradeUrl: "Firmware upgrade url",
                RebootMediaspot: "Reboot mediaspot",
                DeleteMediaspot: "Delete mediaspot",
                DeleteMediaspotDescription1: "Use this button to remove mediaspots that are not in use anymore." ,
                DeleteMediaspotDescription2: "Mediaspots which are online will be recreated automatically a few minutes later.",

                // Custom JSON
                CustomJSONDescription: "Any valid JSON can be entered in this field. It will be available to LAN devices at http://api.fastpoint.media/mediaspotinfo",

                // Welcomepage
                WelcomepageDescription: "When connected to the mediaspot a web browser will show a welcome web page. If you provide the url of a zip file, the mediaspot will download it an update its welcome page.",
                WelcomepageZipUrl: "Welcome page zip url",
                WelcomepageFolderContent: "Welcome page folder content on this mediaspot",

                // Wifi
                Name: "Name",
                Band: "Band",
                RadioEnabled: "Radio enabled",
                SSID: "SSID",
                Channel: "Channel",
                TransmitPower: "Transmit power",
                WPAPassword: "WPA Password",
                BssChannelUtilization: "Bss channel utilization",
                ObssChannelUtilization: "Obss channel utilization",
                IdleChannel: "Idle channel",

                // Content provider
                ContentProvider: "Content provider",
                DownloadEnabled: "Download enabled",
                RepositorySynchronization: "Repository synchronization",
                TokenBasedAuthenticationSecret: "Token-based authentication secret",
                Files: "Files",
                Analytics: "Analytics",
                AccessLogs: "Access logs",
                SyncNow: "Sync now",
                ContentsTree: "Contents tree",
                GenerateAnalyticsNow: "Generate analytics now",
                IndexJSON: "Index JSON",

                // Infos
                MediaspotNameUpdate: "Mediaspot name update requested",
                MediaspotCenterHostUpdate: "Mediacenter host name update requested",
                Tr069HostUpdate: "TR069 host name update requested",
                SyncserverWebsocketHostUpdate: "Syncserver websocket host update requested",
                FirmwareUpgradeRequest: "Firmware upgrade requested",

                ClientDeletionRequest: "Client deletion requested",
                SyncRequest: "Sync requested",
                AnalyticsGenerationRequest: "Analytics generation requested",

                CustomJsonRequest: "Custom JSON update requested",
                ErrorCustomJsonRequest: "Error during custom JSON request",

                AllowedTCPPortsRequest: "Allowed TCP Ports update requested",
                AllowedUDPPortsRequest: "Allowed UDP Ports update requested",
                InternetBlockingRequest: "Internet blocking update requested",
                AutomaticCaptivePortalRequest: "Automatic captive portal update requested",
                InternetWhitelistRequest: "Internet white list update requested",

                WelcomepageRequest: "Welcomepage update requested",

                WifiUpdateRequest: "wifi update requests ok",

                // Errors
                ErrorJSONParsing: "Error during JSON parsing",
                ErrorMediaspotNameRequest: "Error during mediaspot name update request",
                ErrorMediacenterHostRequest: "Error during mediacenter host update request",
                ErrorTr069HostRequest: "Error during TR069 host update request",
                ErrorSyncserverWebsocketHostRequest: "Error during syncserver websocket host update request",
                ErrorFirmwareUpgradeRequest: "Error during firmware upgrade request",

                ErrorClientDeletion: "Error during client deletion request",
                ErrorSyncRequest: "Error during sync request",
                ErrorAnalyticsGenerationRequest: "Error during generate analytics request",

                ErrorAllowedTCPPortsRequest: "Error during update request",
                ErrorAllowedUDPPortsRequest: "Error during update request",
                ErrorInternetBlockingRequest: "Error during internet blocking update",
                ErrorAutomaticCaptivePortalRequest: "Error during captive portal update",

                ErrorWhitelistSaving: "Error during whitelist saving",
                ErrorInternetWhitelistRequest: "Error during whitelist update request",

                ErrorWelcomepageRequest: "Error during welcomepage update request",
                ErrorRequestFailed: "request failed",
                ErrorRequestsFailed: "requests failed",

                SaveChanges: "Save changes"
            }
        },
        fr: {
            translations: {
                // Firewall
                UnavailableFirewallInfo: "Informations du parefeu non disponibles",
                FirewallUDPTCPDescription: "Le mediaspot effectue du NAT pour le trafic sortant des périphériques LAN. Les ports sortants autorisés sont définis ci-dessous pour TCP et UDP (liste délimitée par des virgules). Tous les ports entrants sont bloqués.",
                SetAllowedOutgoingTCPPorts: "Définir les ports TCP sortants autorisés",
                SetAllowedOutgoingUDPPorts: "Définir les ports UDP sortants autorisés",
                InternetBlocking: "Blocage d'internet",
                InternetBlockingDescription: "Si désactivé, les périphériques LAN peuvent établir des connexions sortantes vers n'importe quel domaine sur les ports activés. S'il est réglé sur ON, les périphériques LAN peuvent établir des connexions sortantes uniquement vers les domaines répertoriés ci-dessous dans la liste",
                Set: "Appliquer",
                AutomaticCaptivePortal: "Portail captif automatique",
                AutomaticCaptivePortalDescription: "Si activé, les périphériques LAN seront automatiquement dirigés vers la page d'accueil et n'auront pas de connectivité WAN. Ils échappent au portail captif en faisant une requête HTTP à welcomepage.fastpoint.media/portalaccept/",
                InternetWhitelist: "Whitelist internet",
                SaveWhitelist: "Enregistrer la whitelist",

                // Advanced parameters
                MediaspotName: "Nom du mediaspot",
                MediacenterHost: "Hôte du mediacenter",
                Tr069Host: "Hôte du Tr069",
                SyncserverWebsocketHost: "Hôte websocket du syncserver",
                FirmwareUpgradeUrl: "URL de mise à jour du firmware",
                RebootMediaspot: "Redémarrer le mediaspot",
                DeleteMediaspot: "Supprimer le mediaspot",
                DeleteMediaspotDescription1: "Utiliser ce bouton pour supprimer le mediaspot qui ne sera plus jamais utilisé.",
                DeleteMediaspotDescription2: "Les mediaspots en ligne sont re-créés automatiquement quelques minutes après.",

                // Custom JSON
                CustomJSONDescription: "N'importe quel JSON valide peut être saisi dans ce champs. Il sera disponible à http://api.fastpoint.media/mediaspotinfo",
                
                // Welcomepage
                WelcomepageDescription: "Lorsqu'il est connecté à un mediaspot, un navigateur web va montrer une page de bienvenue. Si vous indiquez une url d'un fichier .zip, le mediaspot va le télécharger et mettre sa page de bienvenue à jour.",
                WelcomepageZipUrl: "URL du zip de la page de bienvenue",
                WelcomepageFolderContent: "Contenu du dossier de la page de bienvenue",

                // Wifi
                Name: "Nom",
                Band: "Bande",
                RadioEnabled: "Radio activée",
                SSID: "SSID",
                Channel: "Canal",
                TransmitPower: "Puissance de transmission",
                WPAPassword: "Mot de passe WPA",
                BssChannelUtilization: "Utilisation du canal bss",
                ObssChannelUtilization: "Utilisation du canal obss",
                IdleChannel: "Canal inactif",

                // Content provider
                ContentProvider: "Content provider",
                DownloadEnabled: "Téléchargement activé",
                RepositorySynchronization: "Synchronisation du répertoire",
                TokenBasedAuthenticationSecret: "Secret d'authentification basé sur des tokens",
                Files: "Fichiers",
                Analytics: "Analytique",
                AccessLogs: "Logs d'accès",
                SyncNow: "Synchroniser maintenant",
                ContentsTree: "Arbre du contenu",
                GenerateAnalyticsNow: "Génerer les analytiques maintenant",
                IndexJSON: "Index JSON",

                // Infos
                MediaspotNameUpdate: "Demande de mise à jour du nom du mediaspot",
                MediaspotCenterHostUpdate: "Demandede  mise à jour de l'hôte du mediacenter",
                Tr069HostUpdate: "Demande de mise à jour de l'hôte du TR069",
                SyncserverWebsocketHostUpdate: "Demande de mise à jour de l'hôte websocket du syncserver",
                FirmwareUpgradeRequest: "Demande de mise à jour du firmware",

                ClientDeletionRequest: "Demande de suppression du client",
                SyncRequest: "Demande de synchronisation",
                AnalyticsGenerationRequest: "Demande de génération d'analytiques",

                CustomJsonRequest: "Demande de mise à jour du JSON personnalisé",
                
                AllowedTCPPortsRequest: "Demande de mise à jour des ports TCP autorisés",
                AllowedUDPPortsRequest: "Demande de mise à jour des ports UDP autorisés",
                InternetBlockingRequest: "Demande de mise à jour du blocage d'internet",
                AutomaticCaptivePortalRequest: "Demande de mise à jour du portail captif automatique",
                InternetWhitelistRequest: "Demande de mise à jour de la whitelist internet",
                
                WelcomepageRequest: "Demande de mise à jour de la page de bienvenue",
                
                WifiUpdateRequest: "Demande de mise à jour du Wifi",
                
                // Errors
                ErrorCustomJsonRequest: "Erreur lors de la demande de mise à jour du JSON personnalisé",
                ErrorJSONParsing: "Une erreur s'est produite lors du parsage du JSON",
                ErrorMediaspotNameRequest: "Erreur lors de la demande de mise à jour du nom du mediaspot",
                ErrorMediacenterHostRequest: "Erreur lors de la demande de mise à jour de l'hôte du mediacenter",
                ErrorTr069HostRequest: "Erreur lors de la demande de mise à jour de l'hôte du TR069",
                ErrorSyncserverWebsocketHostRequest: "Erreur lors de la demande de mise à jour de l'hôte du serveur websocket",
                ErrorFirmwareUpgradeRequest: "Erreur lors de la demande de mise à jour du firmware",

                ErrorClientDeletion: "Erreur lors de la demande de suppression du client",
                ErrorSyncRequest: "Erreur lors de la demande de synchronisation",
                ErrorAnalyticsGenerationRequest: "Erreur lors de la demande de génération d'analytiques",

                ErrorAllowedTCPPortsRequest: "Erreur lors de la demande de mise à jour des ports TCP autorisés",
                ErrorAllowedUDPPortsRequest: "Erreur lors de la demande de mise à jour des ports UDP autorisés",
                ErrorInternetBlockingRequest: "Erreur lors de la demande de mise à jour du blocage d'internet",
                ErrorAutomaticCaptivePortalRequest: "Erreur lors de la demande de mise à jour du portal captif automatique",

                ErrorWhitelistSaving: "Erreur lors du traitement de la whitelist internet",
                ErrorInternetWhitelistRequest: "Erreur lors de la demande de mise à jour de la whitelist internet",

                ErrorWelcomepageRequest: "Erreur lors de la demande de mise à jour de la page de bienvenue",
                ErrorRequestFailed: "requête a échoué",
                ErrorRequestsFailed: "requêtes ont échoué",

                SaveChanges: "Enregistrer les changements"
            }
        }
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
