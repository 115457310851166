import axios from "./axios"
export class Config {
    public currentConfig: ConfigurationParameter;
    public token: string
    
    private static instance: Config

    private constructor(){
        this.currentConfig = {
            API_HOST: "localhost",
            API_KEY: "",
            API_PORT: 9000,
            API_PROTOCOL: "http",
            WEBSOCKET_HOST: "localhost",
            WEBSOCKET_PORT: "3061",
        }
    }


    public static getInstance(): Config {
        if(!Config.instance){
            Config.instance = new Config()
        }
        return Config.instance
    }

    

    public update(config: ConfigurationParameter){
        this.currentConfig = config
        axios.defaults.baseURL = `${Config.getInstance().currentConfig.API_PROTOCOL}://${Config.getInstance().currentConfig.API_HOST}:${Config.getInstance().currentConfig.API_PORT}`
    }

    public updateToken(token: string){
        this.token = token
    }
}

interface ConfigurationParameter {
    API_PROTOCOL: string,
    API_HOST: string,
    API_PORT: number,
    API_KEY: string,
    WEBSOCKET_HOST: string,
    WEBSOCKET_PORT: string
}