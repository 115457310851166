import React from "react"
import classes from "./Modal.module.css"

interface IProps {
    title: string
    content: string
    onClose: () => void
}

export const Modal = (props: IProps) => {
    return (
        <>
        <div className={classes.ModalOverlay} onClick={props.onClose}/>
        <div className={classes.Modal}>
            <h2>{props.title}</h2>
            <pre className={classes.ModalContent}>{props.content}</pre>
        </div>
        </>
    )
}