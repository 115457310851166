import { Config } from "../config";
import axios from "../axios"



const getWebsocketServerRootUrl = () => {
    const config = Config.getInstance().currentConfig
    return config.API_PROTOCOL + "://" + config.API_HOST + ":" + config.API_PORT

}

export const websocketWakeUpMediaspot = async(mediaspotId: string): Promise<boolean> => {
    const isConnectedMediaspotResponse = await isConnectedMediaspot(mediaspotId)
    if(!isConnectedMediaspotResponse){
        return false
    }

    try {
        await axios.post(getWebsocketServerRootUrl() + "/informrequest", {
            device_id: mediaspotId
        })
        return true
    } catch(_){
        return false
    }
}

const isConnectedMediaspot = async(mediaspotId: string) => {
    const mediaspotsResponse = await websocketGetMediaspots()
    if(mediaspotsResponse.status !== "success" || !mediaspotsResponse.data || mediaspotsResponse.data.status === false || !mediaspotsResponse.data.result || !mediaspotsResponse.data.result.data){
        return false
    }
    
    return mediaspotsResponse.data.result.data.find(it => {
        return it.mediaspot_id === mediaspotId
    }) !== undefined
}

export const websocketGetMediaspots = async(): Promise<{status:string, data:{ status :boolean, result :{ data :[{mediaspot_id:string, lastinform: string}],status:string}}}> => {
    try {
        const response = await axios.post(getWebsocketServerRootUrl() + "/getwsmediaspots")
        return response.data
    } catch (e) {
        return e;
    }
}
