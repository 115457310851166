import { Config } from "../config"
import { Task } from "../entities/Task"
import { v4 as uuidv4 } from 'uuid';
import { getMediaspotTasks } from "./MediaspotHelper";
export interface TasksListener {
    onTasksUpdated(tasks: Task[]): void
    onTaskAdded(task: Task):void
    onTaskDeleted(task: Task, completed: boolean):void
}

export enum WebsocketSubscriptionType {
    tr069_tasks = "tr069_tasks"
}
 
export class TasksManager {

    private static instance: TasksManager
    private websocket: WebSocket

    private tasks: Task[]

    private taskInsertKey = "tasks_insert"
    private taskDeleteKey = "tasks_delete"

    private constructor(){
        this.tasks = []
        try {
            this.websocket = new WebSocket(`wss://${Config.getInstance().currentConfig.WEBSOCKET_HOST}:${Config.getInstance().currentConfig.WEBSOCKET_PORT}/?connection_id=${uuidv4()}`)
            this.websocket.onopen = this.onOpen
            this.websocket.onmessage = this.onMessage
        }catch(ex){
            console.error("failed to connect to websocket", ex)
        }
    }

    private listeners: Map<WebsocketSubscriptionType, TasksListener[]> = new Map()

    public static getInstance(): TasksManager {
        if(!TasksManager.instance){
            TasksManager.instance = new TasksManager()
            TasksManager.instance.initTasks()
        }
        return TasksManager.instance
    }

    private initTasks = async() => {
        const tasksResponse = await getMediaspotTasks()
        if(tasksResponse.status === true && tasksResponse.data){
            this.tasks = this.tasks.concat(tasksResponse.data)
        }
        
    }

    onOpen = (_ : Event) => {
        console.log("Websocket connection established")
    }

    private onMessage = (event: MessageEvent) => {
        try {
            const message = event.data
            const jsonTasks = JSON.parse(message)

            if(jsonTasks.task === this.taskInsertKey || jsonTasks.task === this.taskDeleteKey){
                if(jsonTasks.task === this.taskInsertKey){
                    this.addTasks(jsonTasks.tasks)
                }
                let taskToDelete: Task
                if(jsonTasks.task === this.taskDeleteKey){
                    // Get a copy of task
                    const task = this.tasks.find(it => it._id === jsonTasks._id)
                    if(task){
                        taskToDelete = {...task}
                    }
                    this.deleteTask(jsonTasks._id)
                }

                this.listeners.get(WebsocketSubscriptionType.tr069_tasks)?.forEach((listener:TasksListener) => {
                    if(jsonTasks.task === this.taskInsertKey){
                        jsonTasks.tasks.forEach((task:Task) => listener.onTaskAdded(task))
                    }
                    if(jsonTasks.task === this.taskDeleteKey){
                        listener.onTaskDeleted(taskToDelete, jsonTasks.complete)
                    }
                    listener.onTasksUpdated(this.tasks)
                })
            }
            
        } catch (error) {
            console.error(error)
        }
    }

    public susbcribe = (topicName: WebsocketSubscriptionType, listener: TasksListener) => {
        // Create map entry if not exists yet
        if(!this.listeners.get(topicName)){
            this.listeners.set(topicName, [])
        }
        // Check if listener not already subscribed
        if(!this.listeners.get(topicName)?.includes(listener)){
            this.listeners.get(topicName)?.push(listener)
        }
    }

    public unsusbcribe = (topicName: WebsocketSubscriptionType, listener: TasksListener) => {
        if(!this.listeners.get(topicName)){
            console.error("No registered listener for topic name : " + topicName)
            return;
        }
        const listenerIndex = this.listeners.get(topicName)?.indexOf(listener)
        if(listenerIndex !== undefined && listenerIndex != -1){
            this.listeners.get(topicName)?.splice(listenerIndex, 1)
        }
    }

    public addTasks = (tasks: Task[]) => {
        const tasksToAdd = []
        for(let task of tasks){
            if(!this.tasks.find(it => it._id === task._id)){
                tasksToAdd.push(task)
            }
        }
        this.tasks = this.tasks.concat(tasksToAdd)
    }

    public deleteTask = (taskId: string) => {
        const task = this.tasks.find(it => it._id === taskId)
        if(task){
            const indexToDelete = this.tasks.indexOf(task)
            this.tasks.splice(indexToDelete, 1)
        }
    }

    public getTasks = (deviceId: string) => {
        return this.tasks.filter(task => task.device === deviceId)
    }

    


}