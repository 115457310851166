//import { RepositorySynchronizationStatus } from "./enums/RepositorySynchronizationStatus";

export class ContentProvider {
    name: string
    isDownloadEnabled: boolean
    syncLogs: string
    indexJSON: string
    contentsTree: string
    tokenBasedAuthenticationSecret?: string
    accessLogs: string
    index: number
    //repositorySynchronizationStatus: RepositorySynchronizationStatus

    constructor(contentProviderJSON: ContentProviderResponse, index: number){
        this.name = contentProviderJSON.name
        this.isDownloadEnabled = contentProviderJSON.isDownloadEnabled
        this.syncLogs = contentProviderJSON.syncLogs
        this.indexJSON = contentProviderJSON.indexJSON
        this.contentsTree = contentProviderJSON.contentsTree
        this.tokenBasedAuthenticationSecret = contentProviderJSON.tokenBasedAuthenticationSecret
        this.accessLogs = contentProviderJSON.accessLogs
        this.index = index
        //this.repositorySynchronizationStatus = contentProviderJSON.repositorySynchronizationStatus
    }
}


export type ContentProviderResponse = {
    name: string
    isDownloadEnabled: boolean
    syncLogs: string
    indexJSON: string
    contentsTree: string
    tokenBasedAuthenticationSecret?: string
    accessLogs: string
    //repositorySynchronizationStatus: RepositorySynchronizationStatus
}