import React from "react"
import classes from "./LoadingCheckbox.module.css"
import Loader from "react-loader-spinner"
import { Trans } from "react-i18next"

type IProps = {
    id: string,
    isLoading: boolean,
    text: string,
    disabled?: boolean,
    onClick?: ((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void) | undefined,

    defaultChecked: boolean
}

export default (props: IProps) => {
    return (
        <>
            {    
                props.isLoading ?
                    <div className={classes.LoadingCheckboxSpinnerContainer}>
                        <div className={classes.LoadingCheckboxSpinnerContent}>
                            <Loader
                                type="Oval"
                                color="#3fa9ff"
                                height={16}
                                width={16}
                            />

                        </div>
                    </div>
                    : <input id={props.id} type="checkbox" defaultChecked={props.defaultChecked} onClick={props.onClick}/>
                }
                <label htmlFor={props.id}><Trans>{props.text}</Trans></label>
            </>
    )
}