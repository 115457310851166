import React, { Component, FormEvent } from "react";
import { WifiInterface } from "../../entities/WifiInterface";
import defaultClasses from "../DefaultStyle.module.css"

type IProps = {
    deviceId: string,
    interface: WifiInterface,
    onUpdate: (wifiInterface: WifiInterface) => void,

}

type IState = {
    interface: WifiInterface
}
export class WifiInterfaceRow extends Component<IProps, IState> {
    
 
    constructor(props: IProps){
        super(props)
        this.state = {
            interface: this.props.interface,
        }
    }
   
    shouldComponentUpdate(_: IProps, nextState: IState){
        if(this.state.interface !== nextState.interface){
            return true
        }
        return false
    }

    onSSIDChanged = async(event: FormEvent<HTMLInputElement>) => {
        const interfaceCopy = {...this.state.interface}
        interfaceCopy.ssid = event.currentTarget.value
        await this.updateInterface(interfaceCopy)
    }
    
    onChannelChanged = async(event: FormEvent<HTMLInputElement>) => {
        const interfaceCopy = {...this.state.interface}
        //TODO: check integer parsing
        interfaceCopy.channel = Number.parseInt(event.currentTarget.value)
        await this.updateInterface(interfaceCopy)
    }
    
    onTransmitPowerChanged = async(event: FormEvent<HTMLInputElement>) => {
        const interfaceCopy = {...this.state.interface}
        //TODO: check integer parsing
        interfaceCopy.transmitPower = Number.parseInt(event.currentTarget.value)
        await this.updateInterface(interfaceCopy)
    }
    
    onWPAPasswordChanged = async(event: FormEvent<HTMLInputElement>) => {
        const interfaceCopy = {...this.state.interface}
        interfaceCopy.wpaPassword = event.currentTarget.value
        await this.updateInterface(interfaceCopy)
    }

    onBSSChannelUtilizationChanged = async(event: FormEvent<HTMLInputElement>) => {
        const interfaceCopy = {...this.state.interface}
        //TODO: check integer parsing
        interfaceCopy.bssChannelUtilization = Number.parseInt(event.currentTarget.value)
        await this.updateInterface(interfaceCopy)
    }

    onOBSSChannelUtilizationChanged = async(event: FormEvent<HTMLInputElement>) => {
        const interfaceCopy = {...this.state.interface}
        //TODO: check integer parsing
        interfaceCopy.obssChannelUtilization = Number.parseInt(event.currentTarget.value)
        await this.updateInterface(interfaceCopy)
    }
    
    onIdleChannelChanged = async(event: FormEvent<HTMLInputElement>) => {
        const interfaceCopy = {...this.state.interface}
        //TODO: check integer parsing
        interfaceCopy.idleChannel = Number.parseInt(event.currentTarget.value)
        await this.updateInterface(interfaceCopy)
    }
    
    updateInterface = async(wifiInterface: WifiInterface) => {
        await this.setState({
            interface: wifiInterface
        })
        this.props.onUpdate(wifiInterface)
    }


    onRadioEnabledCheckboxChanged = async() => {
        const interfaceCopy = {...this.state.interface}
        interfaceCopy.isRadioEnabled = !interfaceCopy.isRadioEnabled
        await this.updateInterface(interfaceCopy)
    }

    
    render(){
        return (
            <tr>
                <td>{this.state.interface.name}</td>
                <td>{this.state.interface.band}</td>
                <td><input type="checkbox" onChange={this.onRadioEnabledCheckboxChanged} checked={this.state.interface.isRadioEnabled}/></td>
                <td><input className={defaultClasses.DefaultInput} type="text" onChange={this.onSSIDChanged} value={this.state.interface.ssid}/></td>
                <td><input className={defaultClasses.DefaultInput} type="number" onChange={this.onChannelChanged} value={this.state.interface.channel}/></td>
                <td><input className={defaultClasses.DefaultInput} type="number" onChange={this.onTransmitPowerChanged} value={this.state.interface.transmitPower}/></td>
                <td><input className={defaultClasses.DefaultInput} type="text" onChange={this.onWPAPasswordChanged} value={this.state.interface.wpaPassword}/></td>
                <td><input className={defaultClasses.DefaultInput} type="number" onChange={this.onBSSChannelUtilizationChanged} value={this.state.interface.bssChannelUtilization}/></td>
                <td><input className={defaultClasses.DefaultInput} type="number" onChange={this.onOBSSChannelUtilizationChanged} value={this.state.interface.obssChannelUtilization}/></td>
                <td><input className={defaultClasses.DefaultInput} type="number" onChange={this.onIdleChannelChanged} value={this.state.interface.idleChannel}/></td>
            </tr>
        )

    }
}