import axios, { AxiosRequestConfig } from "axios"
import {Config} from "./config"

const api = axios.create({
    baseURL: `${Config.getInstance().currentConfig.API_PROTOCOL}://${Config.getInstance().currentConfig.API_HOST}:${Config.getInstance().currentConfig.API_PORT}`
})


api.interceptors.request.use((axiosConfig: AxiosRequestConfig) => {
    axiosConfig.headers["fp-token"] = Config.getInstance().token
    axiosConfig.headers["x-api-key"] = Config.getInstance().currentConfig.API_KEY
    axiosConfig.headers["Authorization"] = `Bearer ${Config.getInstance().token}`

    return axiosConfig
}, (error) => {
    return Promise.reject(error);
})

export default api
