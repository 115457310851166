const zlib = require("browserify-zlib")
export const unzip = async(data: string):Promise<string|undefined> => {
    try {
        
        const buffer = Buffer.from(data, "base64")
        const bufferResponse = await _ungzip(buffer)
        return bufferResponse.toString()
    }catch(err){
        return undefined
    }
}

export const zip = async(data: string):Promise<string|undefined> => {
    try {
        const zipBuffer = await _gzip(data)
        return zipBuffer.toString("base64")
    }catch(err){
        return undefined
    }
}



const _gzip = (input: string, options?: object) => {
    const promise = new Promise(function(resolve, reject) {
        zlib.gzip(input, options, function (error: string, result: Buffer) {
            if(!error) resolve(result);
            else reject(Error(error));
        });
    });
    return promise as Promise<Buffer>;
}

const _ungzip = (input: Buffer, options?: object) => {
    const promise = new Promise(function(resolve, reject) {
        zlib.gunzip(input, options, function (error: string, result: Buffer) {
            if(!error) resolve(result);
            else reject(Error(error));
        });
    });
    return promise as Promise<Buffer>;
}