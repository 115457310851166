import React from "react"
import classes from "./SetButton.module.css"
import Loader from "react-loader-spinner"
import defaultClasses from "../DefaultStyle.module.css"
import { Trans } from "react-i18next"

type IProps = {
    isLoading: boolean,
    text: string,
    height?: number,
    disabled?: boolean,
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
}

export default (props: IProps) => {
    return props.isLoading ?
        <div className={classes.SetButtonSpinnerContainer} style={props.height ? {height: props.height} : {}}>
            <div className={classes.SetButtonSpinnerContent} style={props.height ? {top: props.height * 0.1} : {}}>
                <Loader
                    type="Oval"
                    color="#FFFFFF"
                    height={props.height ? (props.height * 0.8) : 30}
                    width={100}
                />

            </div>
        </div>
        : <button disabled={props.disabled} {...props} style={props.height ? {height: props.height} : {}} className={[defaultClasses.DefaultOKButton, classes.SetButtonValidateButton].join(" ")}><Trans>{props.text}</Trans></button>
}