import React, { ChangeEvent, Component, FormEvent } from "react";
import { I18nextProvider, Trans } from "react-i18next";
import { setCustomJSON } from "../../helpers/MediaspotHelper";
import { unzip, zip } from "../../helpers/ZipHelper";
import i18n from "../../i18n";
import classes from "./CustomJSONComponent.module.css"
import Loader from "react-loader-spinner"

interface IProps {
    deviceId: string,
    customJSON: string,
    onCustomJSONUpdated: (encodedCustomJSON: string) => void,
    addToast: Function
}

interface IState {
    jsonEditingText: string,
    updateInProgress: boolean
}

export class CustomJSONComponent extends Component<IProps, IState> {

    constructor(props: IProps){
        super(props)
        this.state = {
            jsonEditingText: "",
            updateInProgress: false
        }
    }

    async componentDidMount(){
        const decodedCustomJSON = await unzip(this.props.customJSON)
        
        this.setState({
            jsonEditingText: decodedCustomJSON || ""
        })
    }

    async componentDidUpdate(prevProps: IProps){
        if(prevProps !== this.props){
            const decodedCustomJSON = await unzip(this.props.customJSON)
            this.setState({jsonEditingText: decodedCustomJSON || ""})
        }
    }

    onCustomJSONValueChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            jsonEditingText: event.target.value
        })
    }

    onCustomJSONSubmit = async(event: FormEvent) => {
        event.preventDefault()
        
        const encodedJSON = await zip(this.state.jsonEditingText)
        if(!encodedJSON){
            this.props.addToast(i18n.t("ErrorJSONParsing"), {
                appearance: 'error',
                autoDismiss: true,
            })
            return
        }
        
        
        await this.setState({updateInProgress: true})
        const response = await setCustomJSON(this.props.deviceId, encodedJSON)
        await this.setState({updateInProgress: false})

        if(response.status === true){
            this.props.addToast(i18n.t("CustomJsonRequest"), {
                appearance: 'info',
                autoDismiss: true,
            })
            this.props.onCustomJSONUpdated(encodedJSON)
        }else{
            this.props.addToast(i18n.t("ErrorCustomJsonRequest"), {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }

    render(){
        return (
            <I18nextProvider i18n={i18n}>
                        
                <p><Trans>CustomJSONDescription</Trans></p>
                <form onSubmit={this.onCustomJSONSubmit}>
                    <textarea className={classes.CustomJSONInput} value={this.state.jsonEditingText} onChange={this.onCustomJSONValueChanged}/>
                    <br />
                    <div className={classes.CustomJSONSetContainer}>
                        {
                            this.state.updateInProgress === true ?
                            <div className={classes.CustomJSONSpinnerContainer}>
                                <div className={classes.CustomJSONSpinnerContent}>
                                    <Loader
                                        type="Oval"
                                        color="#FFFFFF"
                                        height={30}
                                        width={100}
                                    />

                                </div>
                            </div>
                            : <button className={classes.CustomJSONValidateButton} type="submit"><Trans>Set</Trans></button> 
                        }
                    </div>
                </form>
                
            </I18nextProvider>
        )
        
    }
}