import React, { Component } from "react";
import { WifiInterface } from "../../entities/WifiInterface";
import { setRadioEnabled, setWifiBssChannelUtilization, setWifiChannel, setWifiIdle, setWifiObssChannelUtilization, setWifiSSID, setWifiTransmitPower, setWifiWpaPassword } from "../../helpers/MediaspotHelper";
import { WifiInterfacesList } from "./WifiInterfacesList";
import i18n from "../../i18n";
import SetButton from "../SetButton/SetButton";
import { I18nextProvider } from "react-i18next";
interface IProps {
    deviceId: string,
    interfaces: WifiInterface[],
    onInterfaceUpdate: (wifiInterfaces: WifiInterface[]) => void,
    addToast: Function
}

interface IState {
    interfaces: WifiInterface[]
    originalInterfaces: WifiInterface[],
    isLoading: boolean
}



export class WifiInterfaces extends Component<IProps, IState> {


    constructor(props: IProps){
        super(props)
        this.state = {
            interfaces: [...props.interfaces],
            originalInterfaces: [...props.interfaces],
            isLoading: false
        }
    }

    
    
    shouldComponentUpdate(){
        return true
    }

    onInterfaceUpdated = (wifiInterfaces: WifiInterface[]) => {
        this.setState({
            interfaces: wifiInterfaces
        })
    }



    onSaveInterfacesClicked = async() => {
        const promises: Promise<{ status: boolean }>[] = []
        this.state.interfaces.forEach((it, index) => {
            
            if(it.ssid !== this.state.originalInterfaces[index].ssid){
                promises.push(setWifiSSID(this.props.deviceId, index + 1, it.ssid))
            }

            if(it.isRadioEnabled !== this.state.originalInterfaces[index].isRadioEnabled){
                promises.push(setRadioEnabled(this.props.deviceId, index + 1, it.isRadioEnabled))
            }

            if(it.channel !== this.state.originalInterfaces[index].channel){
                promises.push(setWifiChannel(this.props.deviceId, index + 1, it.channel))
            }

            if(it.transmitPower !== this.state.originalInterfaces[index].transmitPower){
                promises.push(setWifiTransmitPower(this.props.deviceId, index + 1, it.transmitPower))
            }

            if(it.wpaPassword !== this.state.originalInterfaces[index].wpaPassword){
                promises.push(setWifiWpaPassword(this.props.deviceId, index + 1, it.wpaPassword))
            }

            if(it.bssChannelUtilization !== this.state.originalInterfaces[index].bssChannelUtilization){
                promises.push(setWifiBssChannelUtilization(this.props.deviceId, index + 1, it.bssChannelUtilization))
            }

            if(it.obssChannelUtilization !== this.state.originalInterfaces[index].obssChannelUtilization){
                promises.push(setWifiObssChannelUtilization(this.props.deviceId, index + 1, it.obssChannelUtilization))
            }

            if(it.idleChannel !== this.state.originalInterfaces[index].idleChannel){
                promises.push(setWifiIdle(this.props.deviceId, index + 1, it.idleChannel))
            }
        })

        await this.setState({isLoading: true})
        const responses = await Promise.all<{ status: boolean }>(promises)
        await this.setState({isLoading: false})
        const failedRequestCount = responses.filter(it => it.status === false).length
        const successRequestCount = responses.filter(it => it.status === true).length

        if(failedRequestCount > 0){
            this.props.addToast(`${failedRequestCount} ${i18n.t(failedRequestCount > 1 ? "ErrorRequestsFailed" : "ErrorRequestFailed")}`, {
                appearance: 'error',
                autoDismiss: true,
            })
        }

        if(successRequestCount > 0){
            this.props.addToast(`${successRequestCount} ${i18n.t("WifiUpdateRequest")}`, {
                appearance: 'info',
                autoDismiss: true,
            })
        }
        
    }
    

    render(){
        return (
            <I18nextProvider i18n={i18n}>
                <WifiInterfacesList deviceId={this.props.deviceId} interfaces={this.props.interfaces} onInterfaceUpdate={this.onInterfaceUpdated}/>
                <SetButton isLoading={this.state.isLoading} text={"SaveChanges"} disabled={JSON.stringify(this.state.interfaces) === JSON.stringify(this.state.originalInterfaces)} onClick={this.onSaveInterfacesClicked} height={35}/>
            </I18nextProvider>
        )

    }
}