import React from "react"
import { ChangeEvent, Component, FormEvent } from "react"
// import { toast } from "react-toastify"
import { setWelcomepageZipUrl } from "../../helpers/MediaspotHelper"
import classes from "./Welcomepage.module.css"
import defaultClasses from "../DefaultStyle.module.css"
import { I18nextProvider, Trans } from "react-i18next"
import i18n from "../../i18n"
import SetButton from "../SetButton/SetButton"

type IState = { 
    welcomepageZipUrl: string,
    isLoading: boolean
}

type IProps = {
    deviceId: string
    welcomePageTreeContents: string,
    addToast: Function
}

export class WelcomePage extends Component<IProps, IState>{
    
    constructor(props: IProps){
        super(props)
        this.state = {
            welcomepageZipUrl: "",
            isLoading: false
        }
    }


    onWelcomepageUrlChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            welcomepageZipUrl: event.target.value
        })
    }

    onSetWelcomePageZipUrl = async(event: FormEvent) => {
        event.preventDefault()
        await this.setState({isLoading: true})
        const response = await setWelcomepageZipUrl(this.props.deviceId, this.state.welcomepageZipUrl)
        await this.setState({isLoading: false})
        
        if(response.status === true){
            this.props.addToast(i18n.t("WelcomepageRequest"), {
                appearance: 'info',
                autoDismiss: true,
            })
        }else {
            this.props.addToast(i18n.t("ErrorWelcomepageRequest"), {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }

    render(){
        return <I18nextProvider i18n={i18n}>
            <p>
            <Trans>WelcomepageDescription</Trans>
            </p>

            <form onSubmit={this.onSetWelcomePageZipUrl}>
                <label><Trans>WelcomepageZipUrl</Trans> :</label>
                <input className={[defaultClasses.DefaultInput, classes.WelcomepageZipInput].join(" ")} type="url" onChange={this.onWelcomepageUrlChanged} value={this.state.welcomepageZipUrl}/>
                <SetButton isLoading={this.state.isLoading} text={"Set"} height={24}/>
            </form>

           

            <label><Trans>WelcomepageFolderContent</Trans>:</label>
            <br />
            <pre className={classes.WelcomepageTreeTextArea}>
                {this.props.welcomePageTreeContents}
            </pre>
        </I18nextProvider>
        
    }
}