import axiosAPI from "../axios"
import { Mediaspot } from "../entities/Mediaspot"
import { Task } from "../entities/Task"
import { websocketWakeUpMediaspot } from "./WebsocketServerHelper"

const setParameterEndpoint = "setparameter"
const getMediaspotsEndpoint = "getgenieacsdevices"
const getMediaspotEndpoint = "getgenieacsdevice"
const refreshDeviceEndpoint = "refreshObject"
const getMediaspotTasksEndpoint = "gettasks"


enum SetParameterKey {
    RemoveClientByName = "RemoveClientByName",
    DownloadEnabled = "DownloadEnabled",
    SynchronizeNow = "SynchronizeNow",
    UpdateTokenBasedAuthenticationSecret = "UpdateTokenBasedAuthenticationSecret",
    GenerateAnalytics = "GenerateAnalytics",
    WifiBand = "WifiBand",
    WifiChannel = "WifiChannel",
    WifiSSID = "WifiSSID",
    WifiTransmitPower = "WifiTransmitPower",
    WifiRadioEnabled = "WifiRadioEnabled",
    WifiWpaPassword = "WifiWpaPassword",
    WifiBssChannelUtilization = "WifiBssChannelUtilization",
    WifiObssChannelUtilization = "WifiObssChannelUtilization",
    WifiIdle = "WifiIdle",
    AllowedPortsUdp = "AllowedPortsUdp",
    AllowedPortsTcp = "AllowedPortsTcp",
    InternetBlockingEnabled = "InternetBlockingEnabled",
    AutoCaptivePortalEnabled = "AutoCaptivePortalEnabled",
    InternetWhitelist = "InternetWhitelist",
    WelcomepageZipUrl = "WelcomepageZipUrl",
    CustomJSON = "CustomJSON", 
 
    MediaspotName = "MediaspotName",
    MediacenterHost = "MediacenterHost",
    Tr069Host = "TR069Host",
    SyncserverWebsocketHost = "SyncserverWebsocketUrl",
    FirmwareUpgradeUrl = "FirmwareUpgradeUrl"

}


type setParameterRequest = {
    name: SetParameterKey,
    value: string|number|boolean,
    device_id: string,
    index?: number
}


export const getMediaspots = async(clientName?: string) => {
    try {
        const response = await axiosAPI.post(getMediaspotsEndpoint, {
            clientName: clientName
        })
        if(response.data.data){
            return {
                status: true,
                result: response.data.data
            }
        }else {
            return {
                status: false,
            }
        }
    } catch(err){
        return {
            status: false
        }
    }
}

export const getMediaspot = async(deviceId: string) => {
    try {
        const response = await axiosAPI.post(getMediaspotEndpoint, {
            device_id: deviceId
        })
        if(response.data.data){
            
            return {
                status: true,
                result: new Mediaspot(response.data.data)
            }
        }else {
            return {
                status: false,
            }
        }
    } catch(err){
        return {
            status: false
        }
    }
}

export const informRequest = async(deviceId: string) => {
    try {
        const result = await websocketWakeUpMediaspot(deviceId)
        return {
            status: result
        }
    } catch(err){
        return {
            status: false
        }
    }
}

export const refreshDevice = async(deviceId: string) => {
    try {
        await axiosAPI.post(refreshDeviceEndpoint, {
            device_id: deviceId
        })
        return {
            status: true
        }
    } catch(err){
        return {
            status: false
        }
    }
}

const setParameter = async(task: setParameterRequest): Promise<{ status: boolean }> => {
    try {
        await axiosAPI.post(setParameterEndpoint, task)

        await informRequest(task.device_id)
        return {
            status: true
        }
    } catch(err){
        return {
            status: false
        }
    }
}


// ***************************
// ********** TASKS *********
// ***************************

export const getMediaspotTasks = async(deviceId?: string): Promise<{status: boolean, data?: Task[]}> => {
    try {
        const tasksResponse = await axiosAPI.post<Task[]>(getMediaspotTasksEndpoint, {
            device_id: deviceId
        })
        return {
            status: true,
            data: tasksResponse.data
        }
    }catch(error){
        return {
            status: false,
            data: undefined
        }
    }
}



// ***************************
// **** CONTENT PROVIDERS ****
// ***************************

export const removeClientByName = async(deviceId: string, clientName: string) => {
    return await setParameter({
        name: SetParameterKey.RemoveClientByName,
        value: clientName,
        device_id: deviceId
    })
}


export const setDownloadEnabled = async(deviceId: string, clientIndex: number, downloadEnabled: boolean) => {
    return await setParameter({
        name: SetParameterKey.DownloadEnabled,
        value: downloadEnabled,
        device_id: deviceId,
        index: clientIndex
    })
}

export const syncNow = async(deviceId: string, clientIndex: number) => {
    return await setParameter({
        name: SetParameterKey.SynchronizeNow,
        value: true,
        device_id: deviceId,
        index: clientIndex
    })
}

export const updateTokenBasedAuthenticationSecret = async(deviceId: string, clientIndex: number, authenticationSecret: string) => {
    return await setParameter({
        name: SetParameterKey.UpdateTokenBasedAuthenticationSecret,
        value: authenticationSecret,
        device_id: deviceId,
        index: clientIndex
    })
}

export const generateAnalytics = async(deviceId: string, clientIndex: number) => {
    return await setParameter({
        name: SetParameterKey.GenerateAnalytics,
        value: true,
        device_id: deviceId,
        index: clientIndex
    })
}

// **************
// **** WIFI ****
// **************

export const setWifiBand = async(deviceId: string, interfaceIndex: number, wifiBand: string) => {
    return await setParameter({
        name: SetParameterKey.WifiBand,
        value: wifiBand,
        device_id: deviceId,
        index: interfaceIndex
    })
}

export const setWifiChannel = async(deviceId: string, interfaceIndex: number, wifiChannel: number) => {
    return await setParameter({
        name: SetParameterKey.WifiChannel,
        value: wifiChannel,
        device_id: deviceId,
        index: interfaceIndex
    })
}

export const setWifiSSID = async(deviceId: string, interfaceIndex: number, wifiSSID: string) => {
    return await setParameter({
        name: SetParameterKey.WifiSSID,
        value: wifiSSID,
        device_id: deviceId,
        index: interfaceIndex
    })
}

export const setWifiTransmitPower = async(deviceId: string, interfaceIndex: number, transmitPower: number) => {
    return await setParameter({
        name: SetParameterKey.WifiTransmitPower,
        value: transmitPower,
        device_id: deviceId,
        index: interfaceIndex
    })
}

export const setRadioEnabled = async(deviceId: string, interfaceIndex: number, radioEnabled: boolean) => {
    return await setParameter({
        name: SetParameterKey.WifiRadioEnabled,
        value: radioEnabled.toString(),
        device_id: deviceId,
        index: interfaceIndex
    })
}


export const setWifiWpaPassword = async(deviceId: string, interfaceIndex: number, wpaPassword: string) => {
    return await setParameter({
        name: SetParameterKey.WifiWpaPassword,
        value: wpaPassword,
        device_id: deviceId,
        index: interfaceIndex
    })
}

export const setWifiBssChannelUtilization = async(deviceId: string, interfaceIndex: number, bssChannelUtilization: number) => {
    return await setParameter({
        name: SetParameterKey.WifiBssChannelUtilization,
        value: bssChannelUtilization,
        device_id: deviceId,
        index: interfaceIndex
    })
}

export const setWifiObssChannelUtilization = async(deviceId: string, interfaceIndex: number, obssChannelUtilization: number) => {
    return await setParameter({
        name: SetParameterKey.WifiObssChannelUtilization,
        value: obssChannelUtilization,
        device_id: deviceId,
        index: interfaceIndex
    })
}

export const setWifiIdle = async(deviceId: string, interfaceIndex: number, idle: number) => {
    return await setParameter({
        name: SetParameterKey.WifiIdle,
        value: idle,
        device_id: deviceId,
        index: interfaceIndex
    })
}

// ****************
// *** FIREWALL ***
// ****************

export const setAllowedPortsUdp = async(deviceId: string, allowedPortsUdp: string) => {
    return await setParameter({
        name: SetParameterKey.AllowedPortsUdp,
        value: allowedPortsUdp,
        device_id: deviceId
    })
}

export const setAllowedPortsTcp = async(deviceId: string, allowedPortsTcp: string) => {
    return await setParameter({
        name: SetParameterKey.AllowedPortsTcp,
        value: allowedPortsTcp,
        device_id: deviceId
    })
}

export const setInternetBlockingEnabled = async(deviceId: string, internetBlockingEnabled: boolean) => {
    return await setParameter({
        name: SetParameterKey.InternetBlockingEnabled,
        value: internetBlockingEnabled.toString(),
        device_id: deviceId
    })
}

export const setInternetWhitelist = async(deviceId: string, internetWhitelist: string) => {
    return await setParameter({
        name: SetParameterKey.InternetWhitelist,
        value: internetWhitelist,
        device_id: deviceId
    })
}


export const setAutoCaptivePortalEnabled = async(deviceId: string, autoCaptivePortalEnabled: boolean) => {
    return await setParameter({
        name: SetParameterKey.AutoCaptivePortalEnabled,
        value: autoCaptivePortalEnabled.toString(),
        device_id: deviceId
    })
}

export const setWelcomepageZipUrl = async(deviceId: string, welcomepageZipUrl: string) => {
    return await setParameter({
        name: SetParameterKey.WelcomepageZipUrl,
        value: welcomepageZipUrl,
        device_id: deviceId
    })
}


export const setCustomJSON = async(deviceId: string, customJSON: string) => {
    return await setParameter({
        name: SetParameterKey.CustomJSON,
        value: customJSON,
        device_id: deviceId
    })
}


// ################
// *** ADVANCED ***
// ################

export const setMediaspotName = async(deviceId: string, mediaspotName: string) => {
    return await setParameter({
        name: SetParameterKey.MediaspotName,
        value: mediaspotName,
        device_id: deviceId
    })
}

export const setMediacenterHost = async(deviceId: string, mediacenterHost: string) => {
    return await setParameter({
        name: SetParameterKey.MediacenterHost,
        value: mediacenterHost,
        device_id: deviceId
    })
}

export const setTr069Host = async(deviceId: string, tr069Host: string) => {
    return await setParameter({
        name: SetParameterKey.Tr069Host,
        value: tr069Host,
        device_id: deviceId
    })
}

export const setSyncserverWebsocketHost = async(deviceId: string, host: string) => {
    return await setParameter({
        name: SetParameterKey.SyncserverWebsocketHost,
        value: host,
        device_id: deviceId
    })
}

export const setFirmwareUpgradeUrl = async(deviceId: string, firmwareUpgradeUrl: string) => {
    return await setParameter({
        name: SetParameterKey.FirmwareUpgradeUrl,
        value: firmwareUpgradeUrl,
        device_id: deviceId
    })
}
