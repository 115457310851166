import React, { Component } from "react";
import { MediaspotStatusMonitor } from "../../entities/MediaspotStatusMonitor";
import { unzip } from "../../helpers/ZipHelper";
import classes from "./MediaspotStatusMonitor.module.css"

interface IState {
    decodedSystemMonitor?: string
    decodedSystemLog?: string
}

interface IProps {
    mediaspotStatusMonitor: MediaspotStatusMonitor,
}

export class MediaspotStatusMonitorComponent extends Component<IProps, IState> {

    constructor(props: IProps){
        super(props)
        this.state = {
            decodedSystemLog: undefined,
            decodedSystemMonitor: undefined,
        }
    }

    async componentDidMount(){

        const decodedSystemLog = await unzip(this.props.mediaspotStatusMonitor.systemLog)
        const decodedSystemMonitor = await unzip(this.props.mediaspotStatusMonitor.systemMonitor)

        this.setState({
            decodedSystemLog: decodedSystemLog,
            decodedSystemMonitor: decodedSystemMonitor
        })
    }

    async componentDidUpdate(prevProps: IProps){
        if(prevProps.mediaspotStatusMonitor !== this.props.mediaspotStatusMonitor){
            const decodedSystemLog = await unzip(this.props.mediaspotStatusMonitor.systemLog)
            const decodedSystemMonitor = await unzip(this.props.mediaspotStatusMonitor.systemMonitor)

            this.setState({
                decodedSystemLog: decodedSystemLog,
                decodedSystemMonitor: decodedSystemMonitor
            })
        }
    }

    render(){
        return (
            <div>
                <pre className={classes.MediaspotStatusMonitorTextArea}>
                    {this.state.decodedSystemMonitor !== undefined ? this.state.decodedSystemMonitor : undefined}
                </pre>
    
                <pre className={classes.MediaspotStatusMonitorTextArea}>
                    {this.state.decodedSystemLog !== undefined ? this.state.decodedSystemLog : undefined}
                </pre>
            </div>
        )
    }
}