import React, { Component } from "react";
import { I18nextProvider, Trans } from "react-i18next";
import { WifiInterface } from "../../entities/WifiInterface";
import i18n from "../../i18n";
import { WifiInterfaceRow } from "./WifiInterfaceRow";

import classes from "./WifiInterfaces.module.css"

interface IProps {
    deviceId: string,
    interfaces: WifiInterface[],
    onInterfaceUpdate: (wifiInterfaces: WifiInterface[]) => void
}

interface IState {
    interfaces: WifiInterface[]
    originalInterfaces: WifiInterface[]
}



export class WifiInterfacesList extends Component<IProps, IState> {


    constructor(props: IProps){
        super(props)
        this.state = {
            interfaces: [...props.interfaces],
            originalInterfaces: [...props.interfaces]
        }
    }

    componentDidUpdate(prevProps: IProps){
        if(prevProps.interfaces !== this.props.interfaces){
            this.setState({interfaces: this.props.interfaces})
        }
    }
    
    shouldComponentUpdate(prevProps: IProps){
        if(this.props !== prevProps){
            return true
        }
        return false
    }

    onInterfaceUpdated = (wifiInterface: WifiInterface, index: number) => {
        const interfacesCopy = [...this.state.interfaces]
        interfacesCopy[index] = wifiInterface
        this.setState({interfaces: interfacesCopy}, () => {
            this.props.onInterfaceUpdate(this.state.interfaces)
        })
    }
    

    render(){
        const result = (
            this.state.interfaces.map((it, index) => {
                return <WifiInterfaceRow key={it.index} deviceId={this.props.deviceId} onUpdate={(wifiInterface: WifiInterface) => this.onInterfaceUpdated(wifiInterface, index) } interface={it}/>
            })
        )
        return (
            <I18nextProvider i18n={i18n}>
                <table className={classes.WifiInterfacesTable}>
                    <thead>
                        <tr>
                            <td><Trans>Name</Trans></td>
                            <td><Trans>Band</Trans></td>
                            <td><Trans>RadioEnabled</Trans></td>
                            <td><Trans>SSID</Trans></td>
                            <td><Trans>Channel</Trans></td>
                            <td><Trans>TransmitPower</Trans></td>
                            <td><Trans>WPAPassword</Trans></td>
                            <td><Trans>BssChannelUtilization</Trans></td>
                            <td><Trans>ObssChannelUtilization</Trans></td>
                            <td><Trans>IdleChannel</Trans></td>
                        </tr>
                    </thead>
                    <tbody>
                        {result}
                    </tbody>
                </table>
            </I18nextProvider>
        )

    }
}