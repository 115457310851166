import React, { Component } from "react";
import { ContentProvider } from "../../entities/ContentProvider";
import { generateAnalytics, removeClientByName, setDownloadEnabled, syncNow } from "../../helpers/MediaspotHelper";
import { unzip } from "../../helpers/ZipHelper";
//import { toast } from 'react-toastify';
import { Modal } from "../Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import defaultClasses from "../DefaultStyle.module.css"
import { I18nextProvider, Trans } from "react-i18next";
import i18n from "../../i18n"

interface Iprops {
    deviceId: string,
    contentProvider: ContentProvider,
    onClick?: () => void,
    addToast: Function
}

interface IState {
    contentProvider: ContentProvider,

    isModalDisplayed: boolean,
    modalTitle: string,
    modalContent: string,
}

export class ContentProviderRow extends Component<Iprops, IState> {

    constructor(props: Iprops){
        super(props)
        this.state = {
            contentProvider: props.contentProvider,
            isModalDisplayed: false,
            modalTitle: "",
            modalContent: ""
        }
    }

    async componentDidMount(){
        const cpCopy = {...this.props.contentProvider}
        const decodedAccessLogs = await unzip(cpCopy.accessLogs)
        cpCopy.accessLogs = decodedAccessLogs ? decodedAccessLogs : ""

        const decodedIndexJSON = await unzip(cpCopy.indexJSON)
        cpCopy.indexJSON = decodedIndexJSON ? decodedIndexJSON : ""
        
        
        this.setState({
            contentProvider: cpCopy,
        })
    }

    onRemoveClicked = async() => {
        if(window.confirm("Are you sure to remove this content provider on this mediaspot ?")){
            const response = await removeClientByName(this.props.deviceId, this.props.contentProvider.name)
            if(response.status === true){
                this.props.addToast(i18n.t("ClientDeletionRequest"), {
                    appearance: 'info',
                    autoDismiss: true,
                })
            }else {
                this.props.addToast(i18n.t("ErrorClientDeletion"), {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        }
    }


    onDownloadEnabledCheckboxChanged = async() => {
        const cpCopy = {...this.state.contentProvider}
        
        const response = await setDownloadEnabled(this.props.deviceId, this.props.contentProvider.index, !cpCopy.isDownloadEnabled)
        if(response.status === true){
            cpCopy.isDownloadEnabled = !cpCopy.isDownloadEnabled
            this.setState({ contentProvider: cpCopy })
        }else {
            alert("Error during download enabled update")
        }
    }

    onSynchronizeClick = async() => {
        const response = await syncNow(this.props.deviceId, this.props.contentProvider.index)
        if(response.status === true){
            this.props.addToast(i18n.t("SyncRequest"), {
                appearance: 'info',
                autoDismiss: true,
            });
        }else {
            this.props.addToast(i18n.t("ErrorSyncRequest"), {
                appearance: 'error',
                autoDismiss: true,
            });
        }
    }

    onGenerateAnalyticsClicked = async() => {
        const response = await generateAnalytics(this.props.deviceId, this.props.contentProvider.index)
        if(response.status === true){
            this.props.addToast(i18n.t("AnalyticsGenerationRequest"), {
                appearance: 'info',
                autoDismiss: true,
            });
        }else {
            this.props.addToast(i18n.t("ErrorAnalyticsGenerationRequest"), {
                appearance: 'error',
                autoDismiss: true,
            });
        }
    }

    onAccessLogClicked = () => {
        this.setState({
            isModalDisplayed: true,
            modalTitle: "Access log",
            modalContent: this.state.contentProvider.accessLogs
        })
    }

    onIndexJSONClicked = () => {
        this.setState({
            isModalDisplayed: true,
            modalTitle: "Index JSON",
            modalContent: this.state.contentProvider.indexJSON
        })
    }

    onContentsTreeClicked = () => {
        this.setState({
            isModalDisplayed: true,
            modalTitle: "Contents tree",
            modalContent: this.state.contentProvider.contentsTree
        })
    }
    
    showTokenBasedAuthenticationSecretModalInput = () => {
        alert("Show modal with input")
    }


    render(){
        return (
            <I18nextProvider i18n={i18n}>
                {this.state.isModalDisplayed ? <Modal 
                    content={this.state.modalContent}
                    onClose={() => this.setState({isModalDisplayed: !this.state.isModalDisplayed})}
                    title={this.state.modalTitle}
                    /> : null }
                <tr onClick={this.props.onClick}>
                    <td>
                        <button className={defaultClasses.DefaultDeleteButton} onClick={this.onRemoveClicked}>X</button>
                    </td>
                    <td>
                        {this.state.contentProvider.name}
                    </td>
                    <td>
                        <input onChange={this.onDownloadEnabledCheckboxChanged} type="checkbox" checked={this.state.contentProvider.isDownloadEnabled}/>
                        <button className={defaultClasses.DefaultInfoButton} onClick={this.onAccessLogClicked}><Trans>AccessLogs</Trans></button>
                    </td>
                    <td>
                        <button className={defaultClasses.DefaultInfoButton} onClick={this.onSynchronizeClick}><Trans>SyncNow</Trans></button>
                    </td>
                    <td>
                        <input type="text" readOnly={true} disabled={true} value={this.state.contentProvider.tokenBasedAuthenticationSecret}/>
                        <button className={defaultClasses.DefaultInfoButton} type="button" onClick={this.showTokenBasedAuthenticationSecretModalInput}><FontAwesomeIcon icon={faEdit}/></button>
                    </td>
                    <td>
                        <button className={defaultClasses.DefaultInfoButton} onClick={this.onIndexJSONClicked}><Trans>IndexJSON</Trans></button>
                        <button className={defaultClasses.DefaultInfoButton} onClick={this.onContentsTreeClicked}><Trans>ContentsTree</Trans></button>
                    </td>
                    <td>
                        <button className={defaultClasses.DefaultInfoButton} onClick={this.onGenerateAnalyticsClicked}><Trans>GenerateAnalyticsNow</Trans></button>
                    </td>
                </tr>
            </I18nextProvider>
        )

    }
}