import React from "react"
import classes from "./AdvancedMediaspotParameter.module.css"
import { ChangeEvent, Component, FormEvent } from "react";
import { I18nextProvider, Trans } from "react-i18next";

import { setFirmwareUpgradeUrl, setMediacenterHost, setMediaspotName, setSyncserverWebsocketHost, setTr069Host } from "../../helpers/MediaspotHelper";
import i18n from "../../i18n"
import defaultClasses from "../DefaultStyle.module.css"
import SetButton from "../SetButton/SetButton";

interface IState {}

interface IProps {
    deviceId: string,
    mediaspotName: string,
    mediacenterHost?: string,
    tr069Host: string,
    syncserverWebsocketHost: string,
    firmwareUpgradeUrl: string,

    addToast: Function
    
}

interface IState {
    mediaspotName: string
    mediacenterHost: string
    tr069Host: string
    syncserverWebSocketHost: string,
    firmwareUpgradeUrl: string,

    mediaspotNameRequestLoading: boolean
    mediacenterHostRequestLoading: boolean
    tr069HostRequestLoading: boolean
    syncserverWebSocketHostRequestLoading: boolean
    firmwareUpgradeRequestLoading: boolean
}


export class AdvancedMediaspotParameter extends Component<IProps, IState> {

    constructor(props: IProps){
        super(props)

        this.state = {
            mediaspotName: props.mediaspotName,
            mediacenterHost: props.mediacenterHost || "",
            tr069Host: props.tr069Host,
            syncserverWebSocketHost: props.syncserverWebsocketHost,
            firmwareUpgradeUrl: props.firmwareUpgradeUrl,
            mediaspotNameRequestLoading: false,
            mediacenterHostRequestLoading: false,
            tr069HostRequestLoading: false,
            syncserverWebSocketHostRequestLoading: false,
            firmwareUpgradeRequestLoading: false
        }
    }

    componentDidUpdate(prevProps: IProps){
        if(prevProps !== this.props){
            this.setState({
                mediaspotName: this.props.mediaspotName,
                mediacenterHost: this.props.mediacenterHost || "",
                tr069Host: this.props.tr069Host,
                syncserverWebSocketHost: this.props.syncserverWebsocketHost,
                firmwareUpgradeUrl: this.props.firmwareUpgradeUrl,
                mediaspotNameRequestLoading: false,
                mediacenterHostRequestLoading: false,
                tr069HostRequestLoading: false,
                syncserverWebSocketHostRequestLoading: false,
                firmwareUpgradeRequestLoading: false
            })
        }
    }
    
    // Mediaspot name

    onMediaspotNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            mediaspotName: event.target.value
        })
    }

    onSetMediaspotName = async(event: FormEvent) => {
        event.preventDefault()
        
        this.setState({ mediaspotNameRequestLoading: true})
        const response = await setMediaspotName(this.props.deviceId, this.state.mediaspotName)
        this.setState({ mediaspotNameRequestLoading: false})

        if(response.status === true){
            this.props.addToast(i18n.t("MediaspotNameUpdate"), {
                appearance: 'info',
                autoDismiss: true,
            })
        }else {
            this.props.addToast(i18n.t("ErrorMediaspotNameRequest"), {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }


    // Mediacenter host

    onMediacenterHostChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            mediacenterHost: event.target.value
        })
    }

    onSetMediacenterHost = async(event: FormEvent) => {
        event.preventDefault()

        this.setState({ mediacenterHostRequestLoading: true})
        const response = await setMediacenterHost(this.props.deviceId, this.state.mediacenterHost)
        this.setState({ mediacenterHostRequestLoading: false})

        if(response.status === true){
            this.props.addToast(i18n.t("Mediacenter host name update requested"), {
                appearance: 'info',
                autoDismiss: true,
            })
        }else {
            this.props.addToast(i18n.t("ErrorMediacenterHostRequest"), {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }


    // TR069 host

    onTr069HostChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            tr069Host: event.target.value
        })
    }

    onSetTr069Host = async(event: FormEvent) => {
        event.preventDefault()

        this.setState({ tr069HostRequestLoading: true})
        const response = await setTr069Host(this.props.deviceId, this.state.tr069Host)
        this.setState({ tr069HostRequestLoading: false})

        if(response.status === true){
            this.props.addToast(i18n.t("Tr069HostUpdate"), {
                appearance: 'info',
                autoDismiss: true,
            })
        }else {
            this.props.addToast(i18n.t("ErrorTr069HostRequest"), {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }


    // Syncserver websocket host

    onSyncserverWebsocketHostChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            syncserverWebSocketHost: event.target.value
        })
    }

    onSetSyncserverWebsocketHost = async(event: FormEvent) => {
        event.preventDefault()

        this.setState({ syncserverWebSocketHostRequestLoading: true})
        const response = await setSyncserverWebsocketHost(this.props.deviceId, this.state.syncserverWebSocketHost)
        this.setState({ syncserverWebSocketHostRequestLoading: false})

        if(response.status === true){
            this.props.addToast(i18n.t("SyncserverWebsocketHostUpdate"), {
                appearance: 'info',
                autoDismiss: true,
            })
        }else {
            this.props.addToast(i18n.t("ErrorSyncserverWebsocketHostRequest"), {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }
    
    
    // Firmware upgrade url

    onFirmwareUpgradeChanged = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            firmwareUpgradeUrl: event.target.value
        })
    }

    onSetFirmwareUpgrade = async(event: FormEvent) => {
        event.preventDefault()

        this.setState({ firmwareUpgradeRequestLoading: true})
        const response = await setFirmwareUpgradeUrl(this.props.deviceId, this.state.firmwareUpgradeUrl)
        this.setState({ firmwareUpgradeRequestLoading: false})

        if(response.status === true){
            this.props.addToast(i18n.t("FirmwareUpgradeRequest"), {
                appearance: 'info',
                autoDismiss: true,
            })
        }else {
            this.props.addToast(i18n.t("ErrorFirmwareUpgradeRequest"), {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }

    render(){
        return (
            
            <I18nextProvider i18n={i18n}>
                <div className={classes.AdvancedMediaspotParameterContainer}>

                    <form onSubmit={this.onSetMediaspotName}>
                        <label><Trans>MediaspotName</Trans> : </label>
                        <input type="text" value={this.state.mediaspotName} onChange={this.onMediaspotNameChanged}/>
                        <SetButton isLoading={this.state.mediaspotNameRequestLoading} text={"Set"} height={24} />
                    </form>
                    
                    <br />
        
                    <form onSubmit={this.onSetMediacenterHost}>
                        <label><Trans>MediacenterHost</Trans> : </label>
                        <input type="text" value={this.state.mediacenterHost} onChange={this.onMediacenterHostChanged}/>
                        <SetButton isLoading={this.state.mediacenterHostRequestLoading} text={"Set"} height={24} />
                    </form>
                    
                    <br />
        
                    <form onSubmit={this.onSetTr069Host}>
                        <label><Trans>Tr069Host</Trans> : </label>
                        <input type="text" value={this.state.tr069Host} onChange={this.onTr069HostChanged}/>
                        <SetButton isLoading={this.state.tr069HostRequestLoading} text={"Set"} height={24} />
                    </form>
                    
                    <br />
        
                    <form onSubmit={this.onSetSyncserverWebsocketHost}>
                        <label><Trans>SyncserverWebsocketHost</Trans> : </label>
                        <input type="text" value={this.state.syncserverWebSocketHost} onChange={this.onSyncserverWebsocketHostChanged}/>
                        <SetButton isLoading={this.state.syncserverWebSocketHostRequestLoading} text={"Set"} height={24} />

                    </form>
                    
                    <br />
        
                    <form onSubmit={this.onSetFirmwareUpgrade}>
                        <label><Trans>FirmwareUpgradeUrl</Trans> : </label>
                        <input type="text" onChange={this.onFirmwareUpgradeChanged}/>
                        <SetButton isLoading={this.state.firmwareUpgradeRequestLoading} text={"Set"} height={24} />

                    </form>
                    
                    <br />
        
                    <button className={defaultClasses.DefaultWarningButton}><Trans>RebootMediaspot</Trans></button>
                    <hr/>
                    <button className={defaultClasses.DefaultDeleteButton}><Trans>DeleteMediaspot</Trans></button>
        
                    <p>
                        <Trans>DeleteMediaspotDescription1</Trans>
                        <br />
                        <Trans>DeleteMediaspotDescription2</Trans>
                    </p>
                </div>
            </I18nextProvider>
        )
    }
}






